import React from "react";
import topLogo from "../../static/media/top_logo.gif"; // 로고 이미지 import
import "../../static/css/base.css";
import "../../static/css/product_main.css";
import "../../static/css/account_setting.css";
import { useNavigate } from "react-router-dom";

const PDAccountSetting = () => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const navigate = useNavigate();
  // 메뉴 열기/닫기 토글
  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleLoginClick = () => {
    navigate("/login"); // 로그인 페이지로 이동
  };

  const handleJoinClick = () => {
    navigate("/productweb/join_member"); // 회원가입 페이지로 이동
  };

  const handleProductWebClick = (e) => {
    e.preventDefault();
    navigate("/productweb"); // ProductWebMain 페이지로 이동
  };
  
  return (
    <section id="content">
        {/* Content Section */}
        <div className="product-table-container">
          <h2>가족 계정관리</h2>
          <table className="product-table">
            <thead>
              <tr>
                <th>선택</th>
                <th>사용자</th>
                <th>이름</th>
                <th>권한</th>
                <th>아이디</th>
                <th>생성일</th>
                <th>마지막로그인</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><input type="checkbox" /></td>
                <td>지자체_가정</td>
                <td>지자체_독거노인</td>
                <td>실종지</td>
                <td>-</td>
                <td>2024-09-25 10:41:48</td>
                <td>2024-09-25 10:41:49</td>
              </tr>
              <tr>
                <td><input type="checkbox" /></td>
                <td>지자체_가정</td>
                <td>지자체_사용자</td>
                <td>관리자</td>
                <td>public_user</td>
                <td>2024-09-25 01:38:18</td>
                <td>2024-09-25 10:38:18</td>
              </tr>
              <tr>
                <td><input type="checkbox" /></td>
                <td>홍길동 집</td>
                <td>홍길동 할머니</td>
                <td>실종지</td>
                <td>-</td>
                <td>2024-09-24 14:43:20</td>
                <td>2024-09-24 14:43:20</td>
              </tr>
              <tr>
                <td><input type="checkbox" /></td>
                <td>홍길동 집</td>
                <td>홍길동</td>
                <td>관리자</td>
                <td>user3</td>
                <td>2024-09-24 05:42:56</td>
                <td>2024-09-24 14:42:56</td>
              </tr>
              <tr>
                <td><input type="checkbox" /></td>
                <td>김길동 집</td>
                <td>김길동 할머니</td>
                <td>실종지</td>
                <td>-</td>
                <td>2024-09-24 14:41:56</td>
                <td>2024-09-24 14:41:55</td>
              </tr>
            </tbody>
          </table>
          <div className="button-container">
            <button className="add-btn">신규추가</button>
            <button className="edit-btn">선택수정</button>
            <button className="delete-btn">선택삭제</button>
          </div>
        </div>
      </section>
  );
};

export default PDAccountSetting;
