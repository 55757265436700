import React, { useState } from "react";
import topLogo from "../../static/media/top_logo.gif"; // 이미지 파일 import
import "../../static/css/base.css";
import "../../static/css/main.css";
import { useNavigate } from "react-router-dom";

const ProductInfo = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  // 메뉴 열기/닫기 토글
  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleProductWebClick = (e) => {
    e.preventDefault();
    navigate("/productweb"); // ProductWebMain 페이지로 이동
  };

  const handleProductInfoClick = () => {
    navigate("/productweb/productinfo"); // ProductInfo 페이지로 이동
  };

  return (
      <section id="content">
          {/* Content Section */}
        <div className="product">
          <img
            src={topLogo}
            alt="Product Image"
            className="product-image"
            style={{ width: "130px", height: "40px" }}
          />
          <h2 className="product-title">주요기능</h2>
          <p className="product-description">
            여기에 제품 설명이 들어갑니다. 제품의 주요 특징과 장점을 간단히 설명하세요.<br />
            여기에 제품 설명이 들어갑니다. 제품의 주요 특징과 장점을 간단히 설명하세요.<br />
            여기에 제품 설명이 들어갑니다. 제품의 주요 특징과 장점을 간단히 설명하세요.<br />
            여기에 제품 설명이 들어갑니다. 제품의 주요 특징과 장점을 간단히 설명하세요.
          </p>
          {/* 가격 및 구매 버튼 (필요 시 활성화) */}
          {/* <span className="product-price">$ 가격</span>
          <button className="buy-button">구매하기</button> */}
        </div>
      </section>
  );
};

export default ProductInfo;
