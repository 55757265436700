import React, { useState, useEffect, useRef } from 'react';
import "../../static/css/base.css";
import "../../static/css/main.css";
import axios from "axios";
import Modalframe from "../Modal/UserInfoUpdate";
import DeviceRegion from "./DeviceRegion";
import { useCookies } from "react-cookie";
import { baseUrl } from "../../baseURL";


const VideoPlayer = ({ videoUrls }) => {
  const videoRef = useRef(null);
  const currentVideoIndex = useRef(0);

  // videoUrls가 유효한지 확인
  if (!videoUrls || videoUrls.length === 0) {
    return <span className='content_img'>재생된 컨텐츠가 존재하지 않습니다.</span>;
  }

  const handleEnded = () => {
    currentVideoIndex.current += 1;
    if (currentVideoIndex.current < videoUrls.length) {
      videoRef.current.src = videoUrls[currentVideoIndex.current];
      videoRef.current.play();
    } else {
      // 모든 비디오가 끝나면 처음으로 돌아가기
      currentVideoIndex.current = 0;
      videoRef.current.src = videoUrls[currentVideoIndex.current];
      // videoRef.current.play();
    }
  };

  return (
    <video
      className='content_img'
      ref={videoRef}
      controls
      // width="600"
      onEnded={handleEnded}
      src={videoUrls[0]} // 첫 번째 비디오의 URL
    >
      Your browser does not support the video tag.
    </video>
  );
};


const DeviceManagement = () => {
  const baseurl = baseUrl();
  // checkbox체크 저장할 State
  const [checkedIdsSet, setCheckedIdsSet] = useState(new Set());
  const [lastState, setLastState] = useState(null);
  const [recentWatch, setRecentWatch] = useState(null);
  const [audioPath, setAudioPath] = useState(null);
  const [videoPath, setVideoPath] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const [userKeeper, setUserKeeper] = useState(null);
  const [url, setUrl] = useState(null);
  const [gvContentList, setGvContentList] = useState([]);
  const [fmContentList, setfmContentList] = useState([]);
  const [activeSection, setActiveSection] = useState('device');
  const [isUpdateVisible, setIsUpdateVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [gvAdmin, setGvAdmin] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [adminList, setAdminList] = useState({});
  const [checkedSet, setCheckedSet] = useState(new Set());
  const [userDevice, setUserDevice] = useState(null);

  const [cookies] = useCookies([
    "auth_name",
    "user_idx_id",
    "user_name",
    "account_type",
  ]);

  const handleOpenUpdateModal = () => {
    setIsUpdateVisible(true);
  };

  const handleCloseUpdateModal = () => {
    setIsUpdateVisible(false);
  };

  const handleSelectedDevicesChange = (devices) => {
    setCheckedIdsSet(devices);
    console.log("넘어온 Selected devices:", devices);
  };


  const [refreshFlag, setRefreshFlag] = useState(false);

  const triggerRefresh = () => {
    setRefreshFlag(prevFlag => !prevFlag);
  };

  const handleUpdateSubmit = (device_id) => {
    getUserDevice(device_id);
    triggerRefresh(); // 디바이스 업데이트 후 새로고침 트리거
  };




  const handleDeviceNameClick = (deviceId) => {
    console.log(deviceId, "check값이 넘어오니")
    setUserDevice(deviceId);
    getUserDevice(deviceId);
  };

  useEffect(() => {
    getUserDevice(1);
    getAdminList();
  }, []);


  const handelDeviceRemove = async () => {
    try {
      if (window.confirm("삭제하시겠습니까?")) {
        const response = await axios.delete(`${baseurl}/device-manager`, { data: { device_id: userDevice, user_id: cookies.user_idx_id } })
        if (response.data.success) {
          getUserDevice(1);
          triggerRefresh();
        }
        alert("삭제완료");
      } else {
        alert("취소");
      }
    } catch (error) {

    }
  }


  const categoryContent = (contentArray) => {
    setfmContentList([]);
    setGvContentList([]);
    if (contentArray) {
      contentArray.forEach(content => {
        if (content.type === 1) {
          setfmContentList(prev => [...prev, content]); // 새로운 배열을 반환
        } else {
          setGvContentList(prev => [...prev, content]); // 새로운 배열을 반환
        }
      });
    }
  };

  const getAdminList = async () => {
    try {
      const response = await axios.get(`${baseurl}/device-manager/change-info`);
      console.log(response, " admin list ")
      setAdminList(response.data.admins_array || []);
    } catch (error) {
      console.error("Error fetching getTargerManager:", error);
    }
  }

  const getUserDevice = async (device_id = 1) => {
    try {
      const response = await axios.get(`${baseurl}/device-manager/${device_id}`);
      if (response.data.last_state_array) {
        setLastState(response.data.last_state_array[0].last_state_hms);
      } else {
        setLastState(null)
      }
      if (response.data.recent_watch_array) {
        setRecentWatch(response.data.recent_watch_array[0].recent_watch_time_hms);
      } else {
        setRecentWatch({ recent_watch_time_hms: null })
      }

      // videoPath 인코딩 및 상태 업데이트
      if (response.data.last_content_array) {
        const urls = parseJsonString(response.data.last_content_array[0].video_path);
        let encodedURI = [];
        if (urls == [] || urls.length == 0) {
          console.log(response.data.last_content_array[0].video_path, "비디오 한개 인지 확인")
          encodedURI.push(encodeURI(response.data.last_content_array[0].video_path));
        } else {
          for (const url of urls) {
            encodedURI.push(encodeURI(url));
          }
        }
        console.log(encodedURI, "영상이 잘 변환 됬는지 볼게")
        setVideoPath(encodedURI);
        setAudioPath(response.data.last_content_array[0].audio_path);

      } else {
        setVideoPath([]);
        setAudioPath(null);
      }
      if (response.data.user_info_array && response.data.user_info_array.length > 1) {
        setUserInfo(response.data.user_info_array[1]);
      } else {
        setUserInfo({ name: "(대상자가없음)", addr: "-", device_id: null, add_3_id: null });
      }

      if (response.data.user_info_array) {
        setUserKeeper(response.data.user_info_array[0].name);
        console.log(response.data.user_info_array[0].name, "대상자 정보");
      } else {
        setUserKeeper("-")
      }
      if (response.data.url_array) {
        setUrl(response.data.url_array[0].url);
      } else {
        setUrl("url 주소가 존재하지 않습니다.");
      }
      categoryContent(response.data.content_array);
      setGvAdmin(response.data.device_info_array);

      if (response.data.device_info_array) {
        const checkAdmin = response.data.device_info_array;
        setCheckedSet([]);
        setCheckedSet(prev => {
          const newSet = new Set(prev);
          checkAdmin.forEach(admin => newSet.add(admin.user_id));
          return newSet;
        });
      }

      console.log(response.data, " 기기정보");
      //console.log(encodedURI, "인코딩된 비디오 URL"); // 인코딩된 URL을 콘솔에 출력
    } catch (error) {
      console.error(error);
    }
  };


  function parseJsonString(jsonString) {
    try {
      // JSON 문자열을 객체로 변환
      const parsedObject = JSON.parse(jsonString);
      console.log(Object.values(parsedObject), "parsed path list")
      // 배열로 변환
      return Object.values(parsedObject);
    } catch (error) {
      console.error("JSON 파싱 오류:", error);
      return [];
    }
  }


  const toggleEditMode = async () => {
    try {
      console.log(gvAdmin, "수정 전 작업");
      if (!gvAdmin) {
        throw new Error("유효하지 않은 사용자 정보입니다.");
      }

      const Request = {
        device_id: gvAdmin[0].device_id,
        admins: Array.from(checkedSet),
        dong_id: gvAdmin[0].add_3_id
      }
      console.log(Request, "바뀐 admin list")
      const response = await axios.put(`${baseurl}/device-manager`, Request);
      console.log(response, "admin list");
      getUserDevice(response.data.device_id);
      alert("수정완료");
      setIsEditMode(prevState => !prevState);
    } catch (error) {
      console.error("Error in toggleEditMode:", error);
      alert("수정 중 오류가 발생했습니다: " + error.message);
    }
  };


  const handlechecked = (checkId) => {
    setCheckedSet(prevSet => updateSet(prevSet, checkId));
  };

  const updateSet = (set, id) => {
    const updatedSet = new Set(set);
    if (updatedSet.has(id)) {
      updatedSet.delete(id);
    } else {
      updatedSet.add(id);
    }
    return updatedSet;
  };

  useEffect(() => {
    // console.log(gvAdmin, "뭐라고나오니???")
  }, [gvAdmin]);

  useEffect(() => {
    //console.log(lastState, "업데이트된 마지막 상태");
  }, [lastState]);

  useEffect(() => {
    //console.log(videoPath, "업데이트된 비디오 URL"); // videoPath 상태가 변경될 때 출력
  }, [videoPath]);

  useEffect(() => {

    // console.log(adminList, "admin ..............")
  }, [adminList]);


  return (
    <div className="right subpage">
      <h1 className="device_title">기기관리</h1>
      <div className="management_wrp">
        <DeviceRegion onSelectedDevicesChange={handleSelectedDevicesChange}
          onDeviceNameClick={handleDeviceNameClick}
          refreshFlag={refreshFlag} // 새로고침 플래그를 DeviceRegion에 전달
        />

        <div className="manage_right">
          {isUpdateVisible && (
            <Modalframe
              onClose={handleCloseUpdateModal}
              onSubmit={handleUpdateSubmit}
              initialData={userDevice}
            />
          )}
          <div className="manage_top">
            <h2>{userInfo.name}</h2>
          </div>
          <div className="manage_top_btn">
            <button className="manage_btn01" onClick={handleOpenUpdateModal} >정보변경</button>
            <button className="manage_btn02" onClick={handelDeviceRemove}>기기삭제</button>
          </div>

          <div className="infor_wrp">
            <div className={`inforbox ib01 ${activeSection === 'device' ? 'on' : ''}`}>
              <h6><a href="#" onClick={() => setActiveSection('device')}><span>기기정보</span></a></h6>
              <div className="infor_txtbox on">
                <div className={`device_infor ${activeSection === 'device' ? '' : 'hide'}`}>
                  <p className="reboot">평균시청시간  <button><img src="../static/media/deswrp_icon03.gif" alt="" /></button></p>
                  <p className="reset">최근시청시간 : {recentWatch}<button><img src="../static/media/deswrp_icon02.gif" alt="" /></button></p>
                  <p className="realtime">
                    마지막상태감지 : {lastState}
                  </p>
                  <p className="realtime">마지막재생컨텐츠 </p>


                  {(audioPath && audioPath != "undefined" && audioPath.trim()) ? (
                    <audio
                      controls
                      style={{
                        width: "100%",
                        maxWidth: "600px",
                        margin: "20px auto",
                        display: "block",
                        position: "absolute",
                        bottom: "200px",
                        left: "200px"
                      }}
                    >
                      <source src={audioPath} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                  ) : (
                    <VideoPlayer videoUrls={videoPath} />
                    // <video
                    //   controls
                    //   className="content_img"
                    //   /* style={{
                    //     width: "900px",
                    //     height: "470px",
                    //     borderRadius: "10px",
                    //     margin: "0 auto",
                    //   }} */
                    //   src={videoPath}
                    //   type="video/mp4"
                    // >
                    //   Your browser does not support the video tag.
                    // </video>
                  )}

                  {/*  <p className="content_img"><img src="" alt="" /></p> */}
                  <div className="table">
                    <table className="tb01">
                      <colgroup>
                        <col width="20%" />
                        <col width="30%" />
                        <col width="20%" />
                        <col width="30%" />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th scope="row">Up time</th>
                          <td>2024년 01월 09일 08시 01분</td>
                          <th scope="row">운영체계</th>
                          <td>ANDROID (23)</td>
                        </tr>
                        <tr>
                          <th scope="row">PIN코드</th>
                          <td>MyW6-5767-JD9T-DFK5-BKVZ-HFNG-R6PI-1CJJ</td>
                          <th scope="row">SW버전</th>
                          <td>3.0.10</td>
                        </tr>
                        <tr>
                          <th scope="row">IP주소</th>
                          <td>192.168.1.56</td>
                          <th scope="row">슬립모드</th>
                          <td>적용</td>
                        </tr>
                        <tr>
                          <th scope="row">Public IP 주소</th>
                          <td>116.235.0.6</td>
                          <th scope="row">MAC 주소</th>
                          <td>-</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className={`inforbox ib02 ${activeSection === 'target' ? 'on' : ''}`}>
              <h6><a href="#" onClick={() => setActiveSection('target')}><span>대상자 정보</span></a></h6>
              <div className={`device_infor ${activeSection === 'target' ? '' : 'hide'}`}>
                <div className="function">
                  <div className='info-row'><span className="time_t">주소 </span><span>{userInfo.addr}</span></div>
                  <div className='info-row'><span className="time_t">가족관리자 </span><span >{userKeeper}</span></div>
                  <form onSubmit={(e) => { e.preventDefault(); toggleEditMode(); }} className='info-row'>
                    <div>
                      <span className="time_t">지자체 관리자 </span>
                      {adminList.length > 0 && adminList.map(admin => (
                        <label key={admin.user_id}>
                          <input
                            type="checkbox"
                            onChange={() => handlechecked(admin.user_id)}
                            checked={checkedSet.has(admin.user_id)}
                          />
                          {admin.name}
                        </label>
                      ))}
                    </div>
                    <span>
                      <button type="submit" className="savebtn">수정</button>
                    </span>
                  </form>
                </div>
              </div>
            </div>
            <div className={`inforbox ib03 ${activeSection === 'url' ? 'on' : ''}`}>
              <h6><a href="#" onClick={() => setActiveSection('url')}><span>URL</span></a></h6>
              <div className={`device_infor ${activeSection === 'url' ? '' : 'hide'}`}>
                <div className="url_wrap">
                  <p className="tit"></p>
                  <ul>
                    <li><span className="url_t">URL</span><span className='url_info'>{url}</span>
                      {/* <input type="text" /> */}
                    </li>
                  </ul>
                  {/* <p><button className="savebtn">저 장</button></p> */}
                </div>
              </div>
            </div>
            <div className={`inforbox ib04 ${activeSection === 'playlist' ? 'on' : ''}`}>
              <h6><a href="#" onClick={() => setActiveSection('playlist')}><span>재생목록</span></a></h6>
              <div className={`device_infor ${activeSection === 'playlist' ? '' : 'hide'}`}>
                <div className="playlist">
                  <p className="play_tit"><label htmlFor="play_new_button">재생중인 카테고리 목록</label> <button id="play_new_button"><img src="../static/media/deswrp_icon04.gif" alt="" /></button></p>
                  <ul>
                    <li>
                      <p className="list_subtit">지자체 컨텐츠 <button><img src="../static/media/deswrp_icon05.gif" alt="" /></button></p>
                      <div className="play_list_wrp">
                        <ul>
                          {gvContentList && gvContentList.length > 0 ? (
                            gvContentList.map((content) => (
                              <li key={content.id}>{content.title} <button><img src="../static/media/deswrp_icon06.gif" alt="" /></button></li>
                            ))
                          ) : (
                            <>
                              <li>컨텐츠를 등록해주세요. <button><img src="../static/media/deswrp_icon06.gif" alt="" /></button></li>
                            </>
                          )}
                        </ul>
                      </div>
                    </li>
                    <li>
                      <p className="list_subtit">가족 컨텐츠<button><img src="../static/media/deswrp_icon05.gif" alt="" /></button></p>
                      <div className="play_list_wrp">
                        <ul>
                          {fmContentList && fmContentList.length > 0 ? (
                            fmContentList.map((content) => (
                              <li key={content.id}>{content.title} <button><img src="../static/media/deswrp_icon06.gif" alt="" /></button></li>
                            ))
                          ) : (
                            <>
                              <li>컨텐츠를 등록해주세요. <button><img src="../static/media/deswrp_icon06.gif" alt="" /></button></li>
                            </>
                          )}
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceManagement;


