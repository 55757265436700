import React, { useState } from "react";
import { useCookies } from "react-cookie";
import "../../static/css/base.css";
import "../../static/css/main.css";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../baseURL";
import seniorLogo from '../../static/media/senior.png';

const Login = () => {
  const url = baseUrl();
  const [userId, setUserId] = useState("");
  const [userPw, setUserPw] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies([
    "rememberUserId",
    "autoUserId",
    "autoUserPw",
    "connect.sid",
  ]);
  const [isRemember, setIsRemember] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const loginData = {
      id: userId,
      password: userPw,
      account_type: "admins",
    };

    console.log("User ID:", userId);
    console.log("User Password:", userPw);

    try {
      const response = await fetch(`${url}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // 쿠키를 포함하기 위해 추가
        body: JSON.stringify(loginData),
      });

      const data = await response.json();
      console.log("로그인했다~", data);

      if (data.success) {
        const userInfo = data.object[0];
        // 로그인 성공 시 쿠키에 userId와 userPw 저장
        setCookie("userId", userId, { path: "/" });
        setCookie("userAuth", userInfo.auth, { path: "/" }); // 권한 저장
        setCookie("auth_name", userInfo.auth_name, { path: "/" }); // 권한 저장
        setCookie("user_name", userInfo.name, { path: "/" }); // 권한 저장
        setCookie("user_idx_id", userInfo.user_id, { path: "/" }); // 권한 저장
        setCookie("group_id", userInfo.group_id, { path: "/" }); // 그룹아이디 저장
        setCookie("account_type", userInfo.account_type, { path: "/" }); // 그룹아이디 저장
        setCookie("session_key", userInfo.session_key, { path: "/" });
        // setCookie(,userInfo.,{ path: "/"});

        // 로그인 성공시 Mainframe으로 리디렉션
        navigate("/main");
        console.log(userInfo + "로그인 성공시");
      } else {
        alert("아이디와 비밀번호 확인");
      }
    } catch (error) {
      console.log("로그인 실패~", error);
      alert("로그인 중 오류가 발생했습니다.");
    }
  };

  return (
    <div id="wrap">
      <hr />
      <div className="contents login_page">
        <div className="login_box">
          <h2>시니어케어시스템</h2>
          <form onSubmit={handleSubmit}>
            <div className="inpbox">
              <div className="icell01 icell">
                <span>
                  <label htmlFor="userid">아이디</label>
                </span>
                <input
                  type="text"
                  name="userid"
                  className="userid"
                  id="userid"
                  autoComplete="off"
                  title="아이디"
                  placeholder="아이디 입력"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                />
              </div>
              <div className="icell02 icell">
                <span>
                  <label htmlFor="userpw">비밀번호</label>
                </span>
                <input
                  type="password"
                  name="userpw"
                  className="userpw"
                  id="userpw"
                  autoComplete="off"
                  title="비밀번호"
                  placeholder="비밀번호 입력"
                  value={userPw}
                  onChange={(e) => setUserPw(e.target.value)}
                />
              </div>
              <div className="btnwrp clear_fix">
                <button
                  type="submit"
                  title="확인"
                  className="subtn"
                >
                  로 그 인
                </button>
              </div>
            </div>
          </form>
          <p className="footlogo">
            <img
              src={seniorLogo}
              alt="logo"
              style={{ width: "170px", height: "auto" }}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
