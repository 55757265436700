import React, { useState } from "react";
import Login from "./components/Login/Login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Mainframe from "./components/Mainframe";
import MediaPlayer from "./components/Videoframe";
import ParamComponent from "./components/ParamComponent";
import NotificationProvider from './components/Notifications/NotificationProvider';
import ProductWebMain from "./components/ProductWeb/ProductWebMain";
import PDJoinMember from "./components/ProductWeb/PDJoinMember";
import PDAccountSetting from "./components/ProductWeb/PDAccountSetting";
import PDFAQ from "./components/ProductWeb/PDFAQ";
import ProductInfo from "./components/ProductWeb/ProductInfo";
import PDLayout from "./components/ProductWeb/PDLayout";
import PDServiceInfo from "./components/ProductWeb/PDServiceInfo";
import PDServicePlan from "./components/ProductWeb/PDServicePlan";
import PDUserGiude from "./components/ProductWeb/PDUserGiude";
import PDTechnicalSupport from "./components/ProductWeb/PDTechnicalSupport";

function App() {
  const [userInfo, setUserInfo] = useState(null); // 로그인 정보를 저장할 상태
  const handleLogin = (info) => {
    setUserInfo(info); // 로그인 정보를 업데이트
  };

  return (
    <div className="App">
      <NotificationProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Login onLogin={handleLogin}/>} /> {/* 로그인 컴포넌트에 로그인 핸들러 전달 */}
            <Route path="/main/*" element={<Mainframe />} />
            <Route path="/main" element={<Mainframe />} />
            <Route path="/main/:menu" element={<Mainframe />} />
            <Route path="/video" element={<MediaPlayer />} />
            <Route path="/productweb" element={<PDLayout userInfo={userInfo}><ProductWebMain /></PDLayout>} />
            <Route path="/productweb/join_member" element={<PDLayout userInfo={userInfo}><PDJoinMember /></PDLayout>} />
            <Route path="/productweb/account_setting" element={<PDLayout userInfo={userInfo}><PDAccountSetting /></PDLayout>} />
            <Route path="/productweb/FAQ" element={<PDLayout userInfo={userInfo}><PDFAQ /></PDLayout>} />
            <Route path="/productweb/usergiude" element={<PDLayout userInfo={userInfo}><PDUserGiude /></PDLayout>} />
            <Route path="/productweb/technicalsupport" element={<PDLayout userInfo={userInfo}><PDTechnicalSupport /></PDLayout>} />
            <Route path="/productweb/productinfo" element={<PDLayout userInfo={userInfo}><ProductInfo /></PDLayout>} />
            <Route path="/productweb/serviceinfo" element={<PDLayout userInfo={userInfo}><PDServiceInfo/></PDLayout>}/>
            <Route path="/productweb/serviceplan" element={<PDLayout userInfo={userInfo}><PDServicePlan/></PDLayout>}/>
            <Route path="/productweb/layout" element={<PDLayout userInfo={userInfo}/>}/>
            <Route path="/video/:id" element={<ParamComponent />} />
          </Routes>
        </Router>
      </NotificationProvider>
    </div>
  );
}

export default App;
