import React, { useState, useRef } from 'react';
import "../../static/css/base.css";
import "../../static/css/main.css";
import NewAuth from './NewAuth';
import NewFamilly from './NewFamilly';

const Modalframe = ({ onClose, onAuthSubmit, initialData,formType }) => {
    const modalBackground = useRef();

    return (
        <>
            <div className={'modal-container'} ref={modalBackground} onClick={e => {
                if (e.target === modalBackground.current) {
                    onClose();
                }
            }}>
                {/* formType === 'auth' ? 'modal-content02' : 'modal-content' 이게 이제 modal-content와 modal-content02를 따로 클래스를 두고 
                다른 css를 사용하기 위한 조건식 */}
                <div className={formType === 'auth' ? 'modal-content02' : 'modal-content'}>

                    {/* 여기서부터 post보낼 데이터들 <NewAuth/>,<NewFamilly/> 추가할예정 */}
                    <div style={{ textAlign: 'center' }}>{initialData ? '계정 수정' : '계정 추가'}</div>
                    {formType === 'auth' ? (
                        <NewAuth onSubmit={onAuthSubmit} initialData={initialData}/>
                    ) : (
                        <NewFamilly onSubmit={onAuthSubmit} initialData={initialData} />
                    )}
                    <button className={'modal-close-btn'} onClick={onClose}>
                        모달 닫기
                    </button>
                </div>
            </div>
        </>
    );
};

export default Modalframe;
