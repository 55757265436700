// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Notification.css */
.notification {
    position: fixed;
    right: 20px;
    bottom: 20px;
    background-color: #333;
    color: white;
    padding: 50px 60px; /* 크기를 키우기 위해 padding 조정 */
    border-radius: 8px; /* 모서리 둥글게 */
    font-size: 40px; /* 글씨 크기 확대 */
    font-weight: bold; /* 글씨를 두껍게 */
    color: red; /* 글씨를 빨간색으로 */
    opacity: 0;
    transform: translateY(20px);
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  
  .notification.show {
    opacity: 1;
    transform: translateY(0);
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Notification.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,YAAY;IACZ,kBAAkB,EAAE,0BAA0B;IAC9C,kBAAkB,EAAE,YAAY;IAChC,eAAe,EAAE,aAAa;IAC9B,iBAAiB,EAAE,YAAY;IAC/B,UAAU,EAAE,cAAc;IAC1B,UAAU;IACV,2BAA2B;IAC3B,kDAAkD;EACpD;;EAEA;IACE,UAAU;IACV,wBAAwB;EAC1B","sourcesContent":["/* src/components/Notification.css */\n.notification {\n    position: fixed;\n    right: 20px;\n    bottom: 20px;\n    background-color: #333;\n    color: white;\n    padding: 50px 60px; /* 크기를 키우기 위해 padding 조정 */\n    border-radius: 8px; /* 모서리 둥글게 */\n    font-size: 40px; /* 글씨 크기 확대 */\n    font-weight: bold; /* 글씨를 두껍게 */\n    color: red; /* 글씨를 빨간색으로 */\n    opacity: 0;\n    transform: translateY(20px);\n    transition: transform 0.3s ease, opacity 0.3s ease;\n  }\n  \n  .notification.show {\n    opacity: 1;\n    transform: translateY(0);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
