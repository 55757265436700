import React, { useState } from "react";
import topLogo from "../../static/media/top_logo.gif"; // 로고 이미지 import
import serviceInfo1 from "../../static/media/sample/service_info1.png"; // 제품 이미지 import
import serviceInfo2 from "../../static/media/sample/service_info2.png";
import serviceInfo3 from "../../static/media/sample/service_info3.png";
import serviceInfo4 from "../../static/media/sample/service_info4.png";
import "../../static/css/base.css";
import "../../static/css/main.css";
import "../../static/css/service_info.css";

const PDServiceInfo = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  // 메뉴 열기/닫기 토글
  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  return (
      <section id="content">
          {/* Content Section */}
        <div className="product">
          <img src={serviceInfo1} alt="service_info1 Image" className="product-image" />
          <img src={serviceInfo2} alt="service_info2 Image" className="product-image" />
          <img src={serviceInfo3} alt="service_info3 Image" className="product-image" />
          <img src={serviceInfo4} alt="service_info4 Image" className="product-image" />
          
          <img
            src={topLogo}
            alt="Product Image"
            className="product-image"
            style={{ width: "130px", height: "40px" }}
          />
          <h2 className="product-title">시니케어란?</h2>
          <p className="product-description">
            여기에 제품 설명이 들어갑니다. 제품의 주요 특징과 장점을 간단히 설명하세요.<br />
            여기에 제품 설명이 들어갑니다. 제품의 주요 특징과 장점을 간단히 설명하세요.<br />
            여기에 제품 설명이 들어갑니다. 제품의 주요 특징과 장점을 간단히 설명하세요.<br />
            여기에 제품 설명이 들어갑니다. 제품의 주요 특징과 장점을 간단히 설명하세요.
          </p>
          <br /><br />

          <img
            src={topLogo}
            alt="Product Image"
            className="product-image"
            style={{ width: "130px", height: "40px" }}
          />
          <h2 className="product-title">서비스의 장점</h2>
          <p className="product-description">
            여기는 서비스 장점이 들어갑니다.<br />
            여기는 서비스 장점이 들어갑니다.<br />
            여기는 서비스 장점이 들어갑니다.<br />
            여기는 서비스 장점이 들어갑니다.<br />
          </p>
          <br /><br />

          <img
            src={topLogo}
            alt="Product Image"
            className="product-image"
            style={{ width: "130px", height: "40px" }}
          />
          <h2 className="product-title">이용 대상</h2>
          <p className="product-description">
            1. 독거노인<br />
            2. 독거노인<br />
            3. 독거노인<br />
            4. 독거청년
          </p>
          <br /><br />

          <img
            src={topLogo}
            alt="Product Image"
            className="product-image"
            style={{ width: "130px", height: "40px" }}
          />
          <h2 className="product-title">시니케어 사례</h2>
          <p className="product-description">
            이용대상의 긍정적인 사례가 들어갑니다.<br />
            이용대상의 긍정적인 사례가 들어갑니다.<br />
            이용대상의 긍정적인 사례가 들어갑니다.<br />
            이용대상의 긍정적인 사례가 들어갑니다.<br />
          </p>
          <span className="product-price">$ 가격</span>
          <button className="buy-button">구매하기</button>
        </div>
      </section>

  );
};

export default PDServiceInfo;
