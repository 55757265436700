import React, { useEffect, useState, useRef, Children } from "react";
import "../../static/css/product_main.css";
import "../../static/css/base.css";
import { useNavigate } from "react-router-dom";

function ProductWebMain() {
  const [currentItem, setCurrentItem] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);
  const itemHeight = 293;
  const solutionWrapperRef = useRef(null);
  const listItemsRef = useRef([]);
  const imagesRef = useRef([]);
  const slideIntervalRef = useRef(null);
  const mobileSize = window.matchMedia("(max-width: 768px)");
  const navigate = useNavigate();

  // Scrolling items in solution section
  useEffect(() => {
    const solutionWrapper = solutionWrapperRef.current;
    if (!solutionWrapper) return;

    const listItems = solutionWrapper.querySelectorAll("li");
    if (!listItems || listItems.length === 0) return;

    listItemsRef.current = listItems;

    function scrollItems() {
      setCurrentItem((prevItem) => {
        const nextItem = (prevItem + 1) % listItems.length;
        if (solutionWrapperRef.current) {
          solutionWrapperRef.current.style.transform = `translateY(-${
            nextItem * itemHeight
          }px)`;
        }
        return nextItem;
      });
    }

    const scrollInterval = setInterval(scrollItems, 5000);
    return () => clearInterval(scrollInterval);
  }, []);

  const scrollToItem = (index) => {
    const solutionWrapper = solutionWrapperRef.current;
    if (!solutionWrapper) return;

    solutionWrapper.style.transform = `translateY(-${index * itemHeight}px)`;
    setCurrentItem(index);
  };

  // Image slider functionality
  useEffect(() => {
    const images = document.querySelectorAll(".visual_img p");
    const buttons = document.querySelectorAll(".slider_btn button");

    if (images.length > 0 && buttons.length > 0) {
      imagesRef.current = images;

      function slideImage() {
        if (imagesRef.current[currentIndex]) {
          imagesRef.current[currentIndex].classList.remove("active");
        }
        if (buttons[currentIndex]) {
          buttons[currentIndex].classList.remove("active");
        }

        const nextIndex = (currentIndex + 1) % imagesRef.current.length;
        setCurrentIndex(nextIndex);

        if (imagesRef.current[nextIndex]) {
          imagesRef.current[nextIndex].classList.add("active");
        }
        if (buttons[nextIndex]) {
          buttons[nextIndex].classList.add("active");
        }
      }

      // Initial setup
      if (imagesRef.current[currentIndex]) {
        imagesRef.current[currentIndex].classList.add("active");
      }
      if (buttons[currentIndex]) {
        buttons[currentIndex].classList.add("active");
      }

      slideIntervalRef.current = setInterval(slideImage, 20000);

      buttons.forEach((button, index) => {
        button.addEventListener("click", () => {
          clearInterval(slideIntervalRef.current);
          setCurrentIndex(index);
          slideImage();
          slideIntervalRef.current = setInterval(slideImage, 20000);
        });
      });

      return () => {
        clearInterval(slideIntervalRef.current);
        buttons.forEach((button) => {
          button.removeEventListener("click", () => {});
        });
      };
    }
  }, [currentIndex]);

  // Mobile menu functionality
  useEffect(() => {
    const handleResize = () => {
      if (mobileSize.matches) {
        setMenuOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMenu = () => setMenuOpen((prev) => !prev);

  const handleMenuClick = (e, index) => {
    e.preventDefault();
    const subMenus = document.querySelectorAll(".sub_menu");
    if (index < subMenus.length) {
      const subMenu = subMenus[index];
      if (subMenu.style.display === "none" || !subMenu.style.display) {
        closeAllSubMenus();
        subMenu.style.display = "block";
      } else {
        subMenu.style.display = "none";
      }
    }
  };

  const closeAllSubMenus = () => {
    document.querySelectorAll(".sub_menu").forEach((menu) => {
      menu.style.display = "none";
    });
  };

  const handleFAQClick = () => {
    navigate("/productweb/FAQ"); // FAQ 페이지로 이동
  };

  const handleProductInfoClick = () => {
    navigate("/productweb/productinfo"); // ProductInfo 페이지로 이동
  };

  const handleServiceInfo = () => {
    navigate("/productweb/serviceinfo");
  };

  return (
    <section id="content_main">
      <div className="visual_wrp">
        <div className="visual_img">
          <p className="v01"></p>
          <p className="v02"></p>
        </div>
        <div className="slider_btn">
          <ul>
            <li className="btn01">
              <button type="button"></button>
            </li>
            <li className="btn02">
              <button type="button"></button>
            </li>
          </ul>
        </div>
      </div>

      <div className="contents_wrap">
        <div className="lstbox">
          <ul>
            <li className="cont_lst01">
              <a onClick={handleServiceInfo}>
                <p>
                  <span>Service Info</span>서비스 소개
                </p>
              </a>
            </li>
            <li className="cont_lst02">
              <a href="">
                <p>
                  <span>Service plan</span>서비스 플랜
                </p>
              </a>
            </li>
            <li className="cont_lst03">
              <a href="">
                <p>
                  <span>Product</span>제품 구매
                </p>
              </a>
            </li>
            <li className="cont_lst04">
              <a onClick={handleFAQClick}>
                <p>
                  <span>FAQ</span>자주 묻는 질문
                </p>
              </a>
            </li>
          </ul>
        </div>

        <div className="cont_botwrp">
          <div className="notice_wrp">
            <p className="title">공지사항</p>
            <div className="txtbox">
              <ul>
                <li>
                  <a href="">
                    <span className="txt">시니케어 서비스 종료 공지</span>
                    <span className="date">24-10-11</span>
                  </a>
                </li>
                <li>
                  <a href="">
                    <span className="txt">시니케어 리콜 공지</span>
                    <span className="date">24-10-09</span>
                  </a>
                </li>
                <li>
                  <a href="">
                    <span className="txt">시니케어 하드웨어 교체 공지</span>
                    <span className="date">24-10-01</span>
                  </a>
                </li>
                <li>
                  <a href="">
                    <span className="txt">
                      시니케어 하드웨어 업데이트 공지
                    </span>
                    <span className="date">24-09-30</span>
                  </a>
                </li>
                <li>
                  <a href="">
                    <span className="txt">
                      시니케어 소프트웨어 버전 업데이트 공지
                    </span>
                    <span className="date">24-09-25</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="manual">
            <div className="txtwrp">
              <p className="title">시니케어 가이드</p>
              <p className="txt">
                시니케어 이용 방법 확인 시
                <br />
                아래의 제품 사용법 확인
                <br />
                또는 메뉴얼 받기를 눌러주시면 됩니다.
              </p>
            </div>
            <div className="linkwrp">
              <ul>
                <li className="link01">
                  <a href="">제품 사용법</a>
                </li>
                <li className="link02">
                  <a href="">메뉴얼 받기</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProductWebMain;