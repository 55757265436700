import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Notification from './Notification';
import { baseUrl } from '../baseURL';
import { useCookies } from "react-cookie";

const NotificationProvider = ({ children }) => {
  const url = baseUrl();
  const [message, setMessage] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notifiArry, setNotifiArry] = useState([]);
  const [sessionKeys, setSessionKeys] = useState([]);
  const [cookies] = useCookies(["session_key"]);


  useEffect(() => {
    // 웹소켓 연결 설정
    // const socket = new WebSocket(`ws://172.16.2.71:17937`);
    const socket = new WebSocket('wss://emailbox.smsoft.co.kr');
  
    // 서버에서 메시지를 받았을 때
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
  
      if (data && data.dest_notifi_array && data.session_key) {
        setNotifiArry(data.dest_notifi_array[0]);
        setSessionKeys(data.session_key);  // session_key가 배열임
  
        console.log(data, 'WebSocket Data');
  
        // 서버에서 받은 session_keys 배열과 쿠키의 session_key를 비교
        if (Array.isArray(data.session_key) && data.session_key.length > 0) {
          // 서버에서 받은 모든 session_key를 순회하면서 쿠키에 있는 session_key와 비교
          const matchFound = data.session_key.some(key => key === cookies.session_key);
          console.log("Session keys from server:", data.session_key);
          console.log("Session key from cookies:", cookies.session_key);
  
          if (matchFound) {
            const alertMessage = `${data.dest_notifi_array[0].addr}의 ${data.dest_notifi_array[0].name}님의 집에 긴급상황발생!! 전화번호 ${data.dest_notifi_array[0].phone}입니다.`;
            setMessage(alertMessage);
            setShowNotification(true);
          }
        }
      }
    };

    // 웹소켓 연결이 종료되었을 때
    socket.onclose = () => {
      console.log('WebSocket connection closed');
    };

    // 컴포넌트가 언마운트될 때 웹소켓 연결 해제
    return () => {
      socket.close();
    };
  }, [cookies.session_key, notifiArry, sessionKeys]);

  const handleNotificationClose = async () => {
    setShowNotification(false);
    
  };

  return (
    <div>
      {children}
      {showNotification && 
        <Notification 
          message={message} 
          onClose={handleNotificationClose} 
        />
      }
    </div>
  );
};

export default NotificationProvider;
