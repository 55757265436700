import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../baseURL";

const NewAuth = ({ onSubmit, initialData }) => {
    const baseurl = baseUrl();
    const [depsList, setDepsList] = useState([]);
    const [authList, setAuthList] = useState([]);
    const [cities, setCities] = useState([]);
    const [regions, setRegions] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        id: '',
        password: '',
        deps: '',
        auth: '',
        region_id: '',
        city_id: '',
    });

    const [validationErrors, setValidationErrors] = useState({});

    const getAdminSelect = async () => {
        try {
            const response = (await axios.post(`${baseurl}/service/admin/select`)).data;
            const depsList = response.admin_deps_array;
            setDepsList(depsList);
            const authList = response.admin_auth_array;
            setAuthList(authList);
            const cities = response.cities_array;
            setCities(cities);
            const regions = response.regions_array;
            setRegions(regions);

            // console.log(response, "계정 선택 목록 리스트");

        } catch (error) {
            alert("데이터를 불러오지 못했습니다. 잠시후 다시 시도해주세요.")
        }

    }

    useEffect(() => {
        getAdminSelect();
    }, []);

    useEffect(() => {
        if (initialData) {
            setFormData({
                name: initialData.name,
                id: initialData.id,
                password: '', // 수정 시 비밀번호는 빈 값으로 초기화
                deps: initialData.deps,
                auth: initialData.auth,
                region_id: initialData.regions,
                city_id: initialData.cities
            });
        }
    }, [initialData]);


    const handleChange = async (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));

        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));

        if (name === "region_id") {
            // Send a POST request with the selected admin ID
            console.log("check");
            try {
                const url = `${baseurl}/service/admin/select`;
                const response = await axios.post(url, { region_id: value });
                console.log('Admin selected:', response.data);
                setCities(response.data.cities_array);
                // Process response as needed
            } catch (error) {
                console.error('Error sending selected admin ID:', error);
            }
        }

    };

    const validateForm = () => {
        const errors = {};
        if (!formData.name) errors.name = "이름을 입력해주세요.";
        if (!formData.id) errors.id = "아이디를 입력해주세요.";
        if (!formData.password) errors.password = "비밀번호를 입력해주세요.";
        if (!formData.deps) errors.deps = "소속을 선택해주세요.";
        if (!formData.auth) errors.auth = "권한을 선택해주세요.";
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }


        if (initialData) {
            try {
                const url = `${baseurl}/service/admin`;
                const Request = {
                    password: formData.password,
                    user_idx_id: initialData.user_id,
                    id: formData.id,
                    name: formData.name,
                    auth: formData.auth,
                    deps: formData.deps,
                    region_id: formData.region_id,
                    city_id: formData.region_id
                }

                const response = await axios.put(url, Request);
                console.log('서버 응답:', response.data);
                // 서버로부터 응답을 받았을 때 추가적인 처리 (예: 알림, 상태 업데이트 등)
                onSubmit(response.data.success); // 부모 컴포넌트로 응답 데이터 전달

            } catch (error) {
                if (error.response.status === 409) {
                    setValidationErrors(prevErrors => ({
                        ...prevErrors,
                        id: "아이디가 중복됩니다."
                    }));
                } else {
                    console.log('관리자 계정 수정에 실패하였습니다.')
                }
            }
        } else {
            try {
                const url = `${baseurl}/service/admin`;
                const response = await axios.post(url, formData);
                console.log('서버 응답:', response.data);
                // 서버로부터 응답을 받았을 때 추가적인 처리 (예: 알림, 상태 업데이트 등)
                onSubmit(response.data.success); // 부모 컴포넌트로 응답 데이터 전달
            } catch (error) {
                if (error.response.status === 409) {
                    setValidationErrors(prevErrors => ({
                        ...prevErrors,
                        id: "아이디가 중복됩니다."
                    }));
                } else {
                    console.log('관리자 계정 등록에 실패하였습니다.')
                }
            }
        }

        // 폼 데이터 초기화
        setFormData({
            password: '',
            deps: '',
            id: '',
            name: '',
            auth: ''
        });
    };

    return (
        <>
            <form onSubmit={handleSubmit} className="user-management">
                <label>
                    <span>이름:</span>
                    {validationErrors.name && <span style={{ color: 'red', padding: '6px', fontSize: "small", fontWeight: "300" }}>{validationErrors.name}</span>}
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                </label>
                <label>
                    <span>아이디:</span>
                    {validationErrors.id && <span style={{ color: 'red', padding: '6px', fontSize: "small", fontWeight: "300" }}>{validationErrors.id}</span>}
                    <input
                        type="text"
                        name="id"
                        value={formData.id}
                        onChange={handleChange}
                    />
                </label>
                <label>
                    <span>비밀번호:</span>
                    {validationErrors.password && <span style={{ color: 'red', padding: '6px', fontSize: "small", fontWeight: "300" }}>{validationErrors.password}</span>}
                    <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                    />
                </label>
                <label>
                    <span>직급/부서:</span>
                    {validationErrors.deps && <span style={{ color: 'red', padding: '6px', fontSize: "small", fontWeight: "300" }}>{validationErrors.deps}</span>}
                    <select name="deps" value={formData.deps} onChange={handleChange} >
                        <option value="">소속을 선택하세요</option>
                        {depsList.map(dep => (
                            <option key={dep.id} value={dep.id}>{dep.data}</option>
                        ))}
                    </select>
                </label>
                <label>
                    <span>권한:</span>
                    {validationErrors.auth && <span style={{ color: 'red', padding: '6px', fontSize: "small", fontWeight: "300" }}>{validationErrors.auth}</span>}
                    <select name="auth" value={formData.auth} onChange={handleChange}>
                        <option value="">권한을 선택하세요</option>
                        {authList.map(auth => (
                            <option key={auth.id} value={auth.id}>{auth.data}</option>
                        ))}
                    </select>
                </label>
                <label className="admin-region">
                    <div>관할 지역:</div>
                    <select name="region_id" style={{ marginRight: '5px' }} value={formData.region_id} onChange={handleChange}>
                        <option value="">행정구역</option>
                        {regions.map(region => (
                            <option key={region.id} value={region.id}>{region.data}</option>
                        ))}
                    </select>
                    <select name="city_id" value={formData.city_id} onChange={handleChange}>
                        <option value="">시구역</option>
                        {cities.map(city => (
                            <option key={city.id} value={city.id}>{city.data}</option>
                        ))}
                    </select>
                    {/* {validationErrors.city_id && <div style={{ color: 'red', padding: '6px', fontSize: "small", fontWeight: "300" }}>{validationErrors.city_id}</div>} */}
                </label>




                <button className="form_send_btn" type="submit">보내기</button>
            </form>
        </>
    );
};

export default NewAuth;
