import React, { useEffect } from "react";
import "./Notification.css";

const Notification = ({ message, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 7000); // 5초 후 알림 자동 닫기

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className="notification show"> {/* 'show' 클래스를 항상 추가 */}
      {message}
    </div>
  );
};

export default Notification;
