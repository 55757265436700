import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import "../static/css/base.css";
import "../static/css/main.css";
import Serviceframe from "./Service/Serviceframe";
import SeniorFrame from "./Senior/Seniorframe";
import ContentsManagement from "./Contents/ContentsManagement";
import Contentscreate from "./Contents/Contentscreate";
import ContentsDetail from "./Contents/ContentsDetail";
import SystemLog from "./Log/SystemLog";
import DeviceManagement from "./Device/DeviceManagement";
import { useCookies } from "react-cookie";
import axios from "axios";
import { baseUrl } from "../baseURL";
import WebSocketComponent from "./Websocket";

const Mainframe = () => {
  const url = baseUrl();
  const { menu } = useParams(); // URL 파라미터에서 menu 값을 가져옴
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies();
  const [activeMenu, setActiveMenu] = useState(menu || "senior"); // 초기 메뉴 상태 설정


  // 쿠키없이 이동할수없게하는 useEffect
  useEffect(() => {
    if (!cookies.user_idx_id || !cookies.group_id) {
      navigate('/');
    }
  }, [
    cookies
    , navigate]);

  useEffect(() => {
    const path = location.pathname;
    if (path.includes("/contentscreate")) {
      setActiveMenu("contentsmanage/contentscreate");
    } else if (path.includes("/contentsdetail")) {
      setActiveMenu("contentsmanage/contentsdetail");
    } else if (path.includes("/contentsmanage")) {
      setActiveMenu("contentsmanage");
    }
     else if (path.includes('/contents_edit')) {
      setActiveMenu('contentsmanage/contents_edit');
    }
    else {
      const currentPath = path.split("/").pop();
      setActiveMenu(currentPath);
    }
  }, [location]);

  const handleMenuClick = (menu) => {
    // 쿠키 확인
    if (cookies.user_idx_id && cookies.group_id) {
      // contentsmanage의 서브메뉴인 경우 activeMenu를 그대로 사용
    if (menu.startsWith("contentsmanage/")) {
      setActiveMenu(menu);
    } else {
      setActiveMenu(menu);
    }
    navigate(`/main/${menu}`);
  } else {
    alert("쿠키가 만료되었습니다. 다시 로그인해 주세요.");
    navigate("/");
  }
  };

  const logout = async () => {
    
    // 모든 쿠키 삭제
    const domains = [window.location.hostname, "localhost"];
    const paths = ["/"];

    const logoutData = {
      user_idx_id: cookies.user_idx_id,
      user_name: cookies.user_name,
      auth_name: cookies.auth_name,
    };
    try {
      const response = axios.post(`${url}/logout`, logoutData);
      console.log(response.data);
    } catch (err) {
      console.log(err);
    }
    localStorage.clear();

    domains.forEach((domain) => {
      paths.forEach((path) => {
        Object.keys(cookies).forEach((cookie) => {
          removeCookie(cookie, { path: path, domain: domain });
        });
      });
    });

    window.location.href = "/";
  };

  // 메뉴에 따라 렌더링할 컴포넌트 설정
  const renderSubComponent = () => {
    switch (activeMenu) {
      case "service":
        return <Serviceframe />;
      case "senior":
        return <SeniorFrame />;
      case "contentsmanage":
        return <ContentsManagement />;
      case "contentsmanage/contentscreate": // Contentscreate 메뉴 추가
        return <Contentscreate />;
      case "contentsmanage/contentsdetail":
        return <ContentsDetail />;
      case "systemlog":
        return <SystemLog />;
      case "devicemanagement":
        return <DeviceManagement />;
      // 추가적으로 다른 메뉴에 대한 case를 추가할 수 있음
      default:
        return <SeniorFrame />;
    }
  };

  return (
    <div id="wrap">
      <div className="contents">
        <div className="top">
          {/* <p className="logo"><a href=""><img src="../static/media/logo.gif" alt="로고" /></a></p> */}
          <span className="username">
            <h1 className="mainspan01">TV우편함 통합관리 시스템</h1>
            <a href="">
              <span>{cookies.user_name}</span> 님
            </a>
            {/* <WebSocketComponent/> */}
          </span>
        </div>
        <div className="contwrp">
          <div className="left">
            <div className="menu">
              <ul>
                <li
                  className={
                    activeMenu === "senior" ? "on menulist01" : "menulist01"
                  }
                  onClick={() => handleMenuClick("senior")}
                >
                  <a href="#" onClick={(e) => e.preventDefault()}>
                    시니케어시스템<span>Senior Care System</span>
                  </a>
                </li>
                <li
                  className={
                    activeMenu === "devicemanagement"
                      ? "on menulist02"
                      : "menulist02"
                  }
                  onClick={() => handleMenuClick("devicemanagement")}
                >
                  <a href="#" onClick={(e) => e.preventDefault()}>
                    대상지/기기관리<span>Site/Device Management</span>
                  </a>
                </li>
                <li
                  className={
                    activeMenu.startsWith("contentsmanage")
                      ? "on menulist03"
                      : "menulist03"
                  }
                  onClick={() => handleMenuClick("contentsmanage")}
                >
                  <a href="#" onClick={(e) => e.preventDefault()}>
                    컨텐츠관리<span>Content Management</span>
                  </a>
                </li>
                <li
                  className={
                    activeMenu === "service" ? "on menulist04" : "menulist04"
                  }
                  onClick={() => handleMenuClick("service")}
                >
                  <a href="#" onClick={(e) => e.preventDefault()}>
                    서비스관리<span>Service Management</span>
                  </a>
                </li>
                <li
                  className={
                    activeMenu === "systemlog" ? "on menulist05" : "menulist05"
                  }
                  onClick={() => handleMenuClick("systemlog")}
                >
                  <a href="#" onClick={(e) => e.preventDefault()}>
                    시스템로그<span>System Log</span>
                  </a>
                </li>
                <li
                  className={
                    activeMenu === "logout" ? "on menulist06" : "menulist06"
                  }
                  onClick={() => logout()}
                >
                  <a href="#" onClick={(e) => e.preventDefault()}>
                    로그아웃<span>Logout</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* 조건부 렌더링 */}
          {renderSubComponent()}
        </div>
      </div>
    </div>
  );
};

export default Mainframe;
