import React, { useState } from "react";
import topLogo from "../../static/media/top_logo.gif"; // 이미지 파일 import
import "../../static/css/base.css"; // 필요한 CSS 파일 import
import "../../static/css/product_main.css";
import "../../static/css/join_member.css"; 
import { useNavigate } from "react-router-dom";

const PDJoinMember = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  // 모바일 메뉴 열기/닫기
  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleLoginClick = () => {
    navigate("/"); // 로그인 페이지로 이동
  };

  return (
    <section id="content">
        {/* 회원가입 섹션 */}
        <div className="signup-container">
          <h1>회원가입</h1>
          <form id="signupForm">
            <div className="form-group">
              <label className="product-label" htmlFor="username">사용자 이름:</label>
              <input type="text" id="username" name="username" className="signup-input" required placeholder="사용자 이름을 입력하세요" />
            </div>
            <div className="form-group">
              <label className="product-label" htmlFor="phone">전화번호:</label>
              <input type="text" id="phone" name="phone" className="signup-input" required placeholder="전화번호를 입력하세요" />
            </div>
            <div className="form-group">
              <label className="product-label" htmlFor="email">이메일:</label>
              <input type="email" id="email" name="email" className="signup-input" required placeholder="이메일을 입력하세요" />
            </div>
            <div className="form-group">
              <label className="product-label" htmlFor="password">비밀번호:</label>
              <input type="password" id="password" name="password" className="signup-input" required placeholder="비밀번호를 입력하세요" />
            </div>
            <div className="form-group">
              <label className="product-label" htmlFor="confirm-password">비밀번호 확인:</label>
              <input type="password" id="confirm-password" name="confirm-password" className="signup-input" required placeholder="비밀번호를 다시 입력하세요" />
            </div>
            <div className="form-group">
              <button className="button_product" type="submit">회원가입</button>
            </div>
          </form>
          <p className="login-link">이미 계정이 있으신가요? <a onClick={handleLoginClick}>로그인</a></p>
        </div>
      </section>

  );
};

export default PDJoinMember;