import React from "react";
import Login from "./components/Login/Login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Mainframe from "./components/Mainframe";
import MediaPlayer from "./components/Videoframe";
import ParamComponent from "./components/ParamComponent";
import NotificationProvider from './components/NotificationProvider';


function App() {
  return (
    <div className="App">
      <NotificationProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/main/*" element={<Mainframe />} /> {/* * 추가 */}
            <Route path="/main" element={<Mainframe />} />
            <Route path="/main/:menu" element={<Mainframe />} />
            <Route path="/video" element={<MediaPlayer />} />
            <Route
              path="/video/:id"
              element={<ParamComponent />}
              component={ParamComponent}
            />
          </Routes>
        </Router>
        </NotificationProvider>
    </div>
  );
}

export default App;
