import React, { useState } from "react";
import topLogo from "../../static/media/top_logo.gif"; // 로고 이미지 import
import "../../static/css/base.css";
import "../../static/css/main.css";
import "../../static/css/slick.css";
import "../../static/css/FAQ.css";
import { useNavigate } from "react-router-dom";
// FAQ 관련 스타일도 import 필요 시 추가하세요.
// import "../../static/css/FAQ.css";

const PDFAQ = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeFAQ, setActiveFAQ] = useState(null);
  const navigate = useNavigate();

  const handleProductWebClick = (e) => {
    e.preventDefault();
    navigate("/productweb"); // ProductWebMain 페이지로 이동
  };

  // 메뉴 열기/닫기 토글
  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  // FAQ 질문 열기/닫기
  const toggleFAQ = (index) => {
    setActiveFAQ(activeFAQ === index ? null : index);
  };

  return (
    <section id="content">
      {/* Content Section */}
      {/* FAQ Section */}
      <div className="faq-container" id="faq">
        <h1>FAQ</h1>
        <ul className="faq-list">
          {[
            {
              question: "Q1. 서비스는 어떻게 이용하나요?",
              answer:
                "A. 서비스 이용 방법은 다운로드받은 앱으로 로그인 후 컨텐츠를 업로드 하면 됩니다.",
            },
            {
              question: "Q2. 회원가입은 필수인가요?",
              answer:
                "A. 회원가입은 서비스 이용을 위한 필수 절차입니다. 제품 사용을 위해 회원가입을 해야 합니다.",
            },
            {
              question: "Q3. 결제는 어떻게 하나요?",
              answer:
                "A. 결제는 신용카드, 체크카드, 계좌이체 등 다양한 방법으로 가능합니다.",
            },
            {
              question: "Q4. 컨텐츠가 TV에 안나와요.",
              answer: "A. 기술지원 항목 참고바랍니다.",
            },
          ].map((faq, index) => (
            <li key={index} className="faq-item">
              <button className="faq-question" onClick={() => toggleFAQ(index)}>
                {faq.question}
              </button>
              <div
                className={`faq-answer ${activeFAQ === index ? "active" : ""}`}
              >
                <p>{faq.answer}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="guide-container" id="user-manual">
        {/* Guide Section */}
        <h2>사용설명서(여긴 메뉴얼 png로 삽입)</h2>
        <h3>1. 서비스 시작하기</h3>
        <p>서비스를 시작하기 위해 다음 단계를 따라주세요:</p>
        <ol>
          <li>앱을 다운로드하고 설치합니다.</li>
          <li>앱을 실행하고 회원가입을 진행합니다.</li>
          <li>로그인 후, 메인 화면에서 컨텐츠를 선택합니다.</li>
          <li>업로드 버튼을 클릭하여 컨텐츠를 추가합니다.</li>
        </ol>

        <h3>2. 결제 방법</h3>
        <p>결제는 아래와 같은 방법으로 가능합니다:</p>
        <ul>
          <li>신용카드: 카드 정보를 입력하여 결제합니다.</li>
          <li>체크카드: 체크카드를 선택 후 결제합니다.</li>
          <li>계좌이체: 원하는 계좌로 이체합니다.</li>
        </ul>
      </div>
      <div className="guide-container" id="tech-support">
        {/* Technical Support Section */}
        <h3>기술지원</h3>
        <p>
          서비스 이용 중 문제가 발생할 경우 아래의 방법으로 기술 지원을 받으실
          수 있습니다:
        </p>
        <ul>
          <li>TEL. 061-333-8517</li>
          <li>FAX. 061-333-8516</li>
          <li>email: support@smsoft.co.kr</li>
          <li>
            web:{" "}
            <a href="https://www.smsoft.co.kr/" target="_blank">
              지원 페이지
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default PDFAQ;
